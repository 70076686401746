import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Menu from "../components/Menu";

import { Link } from "react-router-dom";

import Meta from '../components/Meta'

import logo from '../assets/logo-white.png';
import ethLogo from '../assets/eth-logo.png';
import sneakPeek1 from '../assets/sp1.jpg';
import sneakPeek2 from '../assets/sp2.jpg';
import sneakPeek3 from '../assets/sp3.jpg';
import sneakPeek4 from '../assets/sp4.jpg';

const Home = () => {

  const pageTitle = 'B4D BULL'

  return (<>      
  
    <div className="splash">
    <Menu className="transparent-menu" />
      <Meta title={pageTitle}/>
      
      <div id="banner" className="d-flex align-items-center justify-content-center flex-column">
        <h1 className='homepage-title text-center'>B4D Bulls</h1>
        <h1 className='homepage-subtitle text-center'>are coming</h1>
        <Link to="/mint" className="btn white-button mt-5">Mint your bull</Link>
      </div>

      <Container>
        <img src={logo} id="homepage-logo" className="mb-5"></img>
        <h1 className='title pt-5 mb-5'>Lore</h1>
        <p className='text-center'>Les B3ARS (pr&eacute;c&eacute;dente collection) ont caus&eacute; le chaos dans l&rsquo;&eacute;cosyst&egrave;me. Cela fait plusieurs mois qu&rsquo;ils m&egrave;nent la danse sur le march&eacute; des cryptomonnaies/NFT, provoquant la panique chez les investisseurs et l&rsquo;effondrement des institutions les plus fragiles. Mais les choses sont sur le point de changer, les B4D Bulls se pr&eacute;parent et la r&eacute;volte a sonn&eacute;. Les Bulls sont diff&eacute;rents des B3ARS en tous points. Ils sont bruyants, exalt&eacute;s et veulent faire exploser les march&eacute;s financiers &agrave; la hausse. Ils ont r&eacute;ussi &agrave; convaincre de nombreux investisseurs de leur vision optimiste et les cours repartent &agrave; la hausse.</p>
        <p className='text-center'>Au d&eacute;but, les B3ARS et B4D Bulls se regardaient avec m&eacute;pris, en pensant que leur enthousiasme &eacute;tait irrationnel pour l&rsquo;un et trop pessimiste pour les autres. Les B3ARS ont commenc&eacute; &agrave; r&eacute;aliser que les Bulls avaient un pouvoir &eacute;norme sur les march&eacute;s financiers et qu'il &eacute;tait peut-&ecirc;tre temps de changer leur fa&ccedil;on de penser. Ils ont commenc&eacute; &agrave; chercher des opportunit&eacute;s d'investissement &agrave; long terme, plut&ocirc;t que de chercher des moyens de gagner rapidement de l'argent.<br />Les Bulls et les B3ARS ont finalement commenc&eacute; &agrave; travailler ensemble, apprenant de leurs diff&eacute;rences et r&eacute;alisant que le march&eacute; a besoin de variations &agrave; la fois &agrave; la hausse et &agrave; la baisse pour &ecirc;tre sain et durable. Ils ont appris &agrave; coexister et &agrave; prosp&eacute;rer ensemble, offrant des opportunit&eacute;s d'investissement pour tous les types d'investisseurs.</p>
        <p className='text-center'><strong>Les B4D Bulls d&eacute;barquent, et ils sont l&agrave; pour tout casser !</strong></p><br />
        <p className='text-center'><strong>666 B4D Bulls &agrave; mint</strong> <br />Plus de 30 artistes ont particip&eacute; &agrave; la cr&eacute;ation d&rsquo;un NFT l&eacute;gendaire. Avec ceux de l&rsquo;artiste principal, cela fait pr&egrave;s de 40 NFT l&eacute;gendaires possibles. Mais les NFT communs ne sont pas en reste, eux aussi sont pr&ecirc;t &agrave; lead le march&eacute;. </p>

        <div className="py-5">

        <h1 className='title m-5'>La collection</h1>

          <Row id="collection" className="mt-5">
            <Col md={6} lg={3} className="text-center mb-5"><h1>666</h1>Toute ressemblance avec le nombre du diable est fortuite</Col>
            <Col md={6} lg={3} className="text-center mb-5"><img src={ethLogo} height="100" className="mb-4" /><br />B4D Bull est sur Ethereum<br />Où d'autre ?</Col>
            <Col md={6} lg={3} className="text-center mb-5"><h1>45$</h1>Prix maximal du mint<br /> (Public Sale)</Col>
            <Col md={6} lg={3} className="text-center mb-5"><h1>10.04</h1>Date de lancement du mint<br />Ou par là...</Col>
          </Row>

        </div>

        <div className="py-5">

          <h1 className='title m-5'>L'équipe</h1>

          <Row>
            <Col md={6} lg={3} className="text-center mb-5"><div className="team-member-bg tm-1 m-auto"></div><h1 className="team-member-name">Funny B4D Bull</h1>Coordinator</Col>
            <Col md={6} lg={3} className="text-center mb-5"><div className="team-member-bg tm-2 m-auto"></div><h1 className="team-member-name">B4D NaABULL</h1>Coordinator</Col>
            <Col md={6} lg={3} className="text-center mb-5"><div className="team-member-bg tm-3 m-auto"></div><h1 className="team-member-name">LoKo Bull</h1>Designer - Illustrator</Col>
            <Col md={6} lg={3} className="text-center mb-5"><div className="team-member-bg tm-4 m-auto"></div><h1 className="team-member-name">Bull Trainer</h1>Dev - NFT Generator</Col>
          </Row>

        </div>


        <div className="py-5">

        <h1 className='title m-5'>Avantages</h1>

        <Row>
            <Col lg={{ span: 8, offset: 2 }} className="spec mb-4">Participation à la loterie</Col>
            <Col lg={{ span: 8, offset: 2 }} className="spec spec-alt mb-4">Mint gratuit d'un grimoire aléatoire Genesis</Col>
            <Col lg={{ span: 8, offset: 2 }} className="spec mb-4">1 avatar standard (rareté aléatoire) avec accès Beta</Col>
            <Col lg={{ span: 8, offset: 2 }} className="spec spec-alt mb-4">Event exclusif</Col>
          </Row>

        </div>

        <div className="py-5">

          <h1 className='title m-5'>Sneak Peek</h1>

          <Row>
            <Col md={6} lg={3} className="mb-4 text-center"><figure className="sp-w"><img src={sneakPeek1}></img></figure></Col>
            <Col md={6} lg={3} className="mb-4 text-center"><figure className="sp-w sp-w2"><img src={sneakPeek2}></img></figure></Col>
            <Col md={6} lg={3} className="mb-4 text-center"><figure className="sp-w"><img src={sneakPeek3}></img></figure></Col>
            <Col md={6} lg={3} className="mb-4 text-center"><figure className="sp-w sp-w2"><img src={sneakPeek4}></img></figure></Col>
          </Row>

        </div>


        </Container>

    </div></>
  )
}

export default Home