import { useEffect, useState } from 'react';
import Menu from "../components/Menu";

import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";

import Meta from '../components/Meta'

import toast, { Toaster } from 'react-hot-toast';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useNetwork, useSigner, useContractRead, useContractEvent } from 'wagmi';
import { ethers } from "ethers";

import abi from '../contracts/ABI.json';

import PhaseOneYoungBullWLJson from '../assets/lists/0.json';
import PhaseOneBullWLJson from '../assets/lists/1.json';
import PhaseOneOldBullWLJson from '../assets/lists/2.json';
import PhaseTwoBullWLJson from '../assets/lists/4.json';
import PhaseTwoOldBullWLJson from '../assets/lists/5.json';
import FreeMintWLJson from '../assets/lists/6.json';

const {MerkleTree} = require('merkletreejs');
const keccak256 = require('keccak256');
window.Buffer = window.Buffer || require('buffer').Buffer;

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractChainName = process.env.REACT_APP_CONTRACT_CHAIN_NAME;
const contractChainId = parseInt(process.env.REACT_APP_CONTRACT_CHAIN_ID);

const mint_groups = [0, 1, 2, 3, 4, 5, 6];
const maxSupply = 666;

const mintGroupNames = ['WL Young Bull', 'WL Bull', 'WL Old Bull', 'Public', 'WL Bull', 'WL Old Bull', 'Free Mint'];

const Check = () => {

    const pageTitle = 'B4D BULL - Vérification'

    const { address, isConnected } = useAccount()
    const { chain } = useNetwork();
    const { data: signerData } = useSigner();

    const[mintGroupsDatas, setMintGroupsDatas] = useState([]);
    const wlJsonFiles = [PhaseOneYoungBullWLJson, PhaseOneBullWLJson, PhaseOneOldBullWLJson, null, PhaseTwoBullWLJson, PhaseTwoOldBullWLJson, FreeMintWLJson];

    const [wlProofs, setWLProofs] = useState([]);
    const [isOnList, setIsOnList] = useState([]);

const fetchMintGroupsDatas = async () => {
    if (!signerData) return;
    if (chain.id != contractChainId) return;

    try {
      const nftContract = new ethers.Contract(contractAddress, abi, signerData);
      const mintGroupPromises = mint_groups.map(async (group) => {
        const mintGroupInfos = await nftContract.getMintGroup(group);
        return { id: group, ...mintGroupInfos };
      });
  
      const mintGroupDataResults = await Promise.all(mintGroupPromises);
      setMintGroupsDatas(mintGroupDataResults);

    } catch (error) {
      console.error(error);
    }
  };    

    // ROLES
    const generateProofs = async () => {
        if (!signerData | !isConnected) return;
        if (chain.id != contractChainId) return;


        let wlAddressesArray = [[],[],[],null,[],[],[]];
        let wlLeavesArray = [[],[],[],null,[],[],[]];
        let wlTreesArray = [[],[],[],null,[],[],[]];
        let wlProofsArray = [[],[],[],null,[],[],[]];

        mint_groups.map(async (group) => {
            if (group != 3) { wlJsonFiles[group].map(a => ( wlAddressesArray[group].push(a.address) )); }
        });

        mint_groups.map(async (group) => {
            if (group != 3) { wlLeavesArray[group] = wlAddressesArray[group].map(address => keccak256(address)); }
        });

        mint_groups.map(async (group) => {
            if (group != 3) { wlTreesArray[group] = new MerkleTree(wlLeavesArray[group], keccak256, {sort: true}); }
        });

        mint_groups.map(async (group) => {
            if (group != 3) { 
                wlProofsArray[group] = wlTreesArray[group].getHexProof(keccak256(address));
            }
        });

        setWLProofs(wlProofsArray);
    }

    const fetchIsOnList = async () => {
        if (!signerData | !isConnected) return;
        if (chain.id != contractChainId) return;
        try {

            const nftContract = new ethers.Contract(contractAddress, abi, signerData);
            const isOnListPromises = mint_groups.map(async (group) => {
                if (group != 3) {
                    const isOnListInfos = await nftContract.isOnList(address, wlProofs[group], group);
                    return isOnListInfos;
                } else { return true; }
            });
        
            const isOnListResults = await Promise.all(isOnListPromises);
            setIsOnList(isOnListResults);

            } catch (err) { console.log(err); }
    }

    const WLBlock = ({ mint_group_id }) => {
   
        const eligibility = isOnList[mint_group_id];
        return (<>{eligibility == true ? (<ListGroup.Item>{mintGroupNames[mint_group_id]}</ListGroup.Item>) : null }</>)
    }


    // Effects
    useEffect(() => {
        generateProofs().then();
        fetchMintGroupsDatas().then();
    }, [signerData])

    useEffect(() => {
        fetchIsOnList().then();
    }, [address, chain, wlProofs])

    const m_checkUI = () => {
        const listItems = mintGroupsDatas.map((mgDatas) => <WLBlock key={mgDatas.id} mint_group_id={mgDatas.id} /> );
        return(<>
        {listItems.length >= 1 ? (<>L'adresse actuellement connectée dispose des autorisations de mint suivantes : <ListGroup horizontal="xl" className="horizontal-list-group d-flex justify-content-center mt-4">{listItems}</ListGroup></>) : null }        
        </>);
    }

    const checkUI = () => {

        if (!isConnected) return;

        return (
            <div className="text-center mb-5">
                { chain.id !== contractChainId ? ( <><p>Veuillez passer sur le réseau {contractChainName}</p><div className="connect-zone my-2"><ConnectButton label="Connecter son wallet" /></div></> )
                : (m_checkUI()) }
            </div>
        )
    }

  return (
    <><Menu className="transparent-menu" />
    <Meta title={pageTitle}/>

    <Container>
        <Row>
            <Col sm={12} className="mt-4"> <h1 className="title my-4">Vérification</h1><div className="connect-zone my-2"><ConnectButton chainStatus="none" label="Connecter son wallet" /></div></Col>
            <Col sm={12} className="mt-4">{signerData ? checkUI() : null }</Col>
        </Row>
    </Container>      
    </>
  )
}

export default Check