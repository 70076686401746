import { BrowserRouter as Router, Route, Routes, Navigate, useLocation  } from 'react-router-dom';

import { Toaster } from 'react-hot-toast';

import Layout from "./layout/Layout";

import Home from "./pages/Home";
import Mint from "./pages/Mint";
import Check from "./pages/Check";

import './styles/App.css';

import '@rainbow-me/rainbowkit/styles.css';

import { getDefaultWallets, RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig} from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

const { chains, provider } = configureChains( [mainnet], [ publicProvider() ]);
const { connectors } = getDefaultWallets({ appName: 'B4DBull', chains });
const wagmiClient = createClient({ autoConnect: true, connectors, provider})

const App = () => {

  return (
    <WagmiConfig client={wagmiClient}>
    <RainbowKitProvider theme={lightTheme({ ...lightTheme.accentColors.red })} chains={chains} modalSize="compact" coolMode>
    <Router>
      <Layout>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/Mint" element={<Mint />} />
            <Route path="/Check" element={<Check />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Toaster position="top-center" toastOptions={{style: { border: '1px solid #4c2b18', padding: '16px', color: '#4c2b18', }, iconTheme: { primary: '#4c2b18', secondary: '#FFF', }}} reverseOrder={false}/>
      </Layout>
    </Router>
    </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;