import { useEffect, useState } from 'react';
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faTwitter, faMedium } from '@fortawesome/free-brands-svg-icons'

import Logo from "../assets/logo-white.png";

<FontAwesomeIcon icon="fa-brands fa-twitter" />

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractChainExplorer = process.env.REACT_APP_CONTRACT_CHAIN_EXPLORER;
const version = process.env.REACT_APP_VERSION;

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <>
      <footer className='text-center text-capitalize mt-auto'>
      <Container fluid>
        <Row>
          <Col lg={{ span:4, offset: 4 }} className="mb-2" id="footer-block">

            <h3 className="mb-0">.: B4D Bull :.</h3>
            <span className="sc">Smart Contract : <a href={`https://${contractChainExplorer}/address/${contractAddress}`} target="_blank">{contractAddress}</a></span><br />
            <span className='made-with-love'>DApp v.{version} made with <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank">RpGmAx</a></span>

          </Col>

        </Row> 
        </Container>
        
      </footer>
    </>
  )
}

export default Footer