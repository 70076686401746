import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Nav, Container, Navbar } from "react-bootstrap";

import Logo from "../assets/logo-white.png";

const Menu = ({ className }) => {

  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => { setUrl(location.pathname); }, [location]);

  return (
    <Container className={`header-container ${className}`} fluid>
    <Container>
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
        <Link
          to="/"
          className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
        >
          <img src={Logo} alt="B4d Bull" width="48" />
        </Link>
        <Nav activeKey={location.pathname}>
          <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 bb-pages">
            <li>
              <Link to="/" className={"px-2" + (url === "/" ? " active" : "")}>
                Accueil
              </Link>
            </li>
            <li>
              <Link to="/mint" className={"px-2" + (url === "/mint" ? " active" : "")}>
                Mint
              </Link>
            </li>
          </ul>
          <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 bb-sn">
            <li>
              <a href="https://twitter.com/Cryptocademia_" target="_blank" rel="noreferrer" className="px-2">
                <div className="sn-icon twitter-icon"></div>
              </a>
            </li>
            <li>
              <a href="https://discord.gg/23ttNBSqvZ" target="_blank" rel="noreferrer" className="px-2">
              <div className="sn-icon discord-icon"></div>
              </a>
            </li>
            <li>
              <a href="https://opensea.io/collection/b4d-bull" target="_blank" rel="noreferrer" className="px-2">
              <div className="sn-icon opensea-icon"></div>
              </a>
            </li>
          </ul>
        </Nav>
      </header>
    </Container>
    </Container>
  );
};

export default Menu;