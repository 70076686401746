import { useEffect, useState } from 'react';
import Menu from "../components/Menu";

import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";

import Meta from '../components/Meta'

import toast, { Toaster } from 'react-hot-toast';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useNetwork, useSigner, useContractRead, useContractEvent } from 'wagmi';
import { ethers } from "ethers";

import abi from '../contracts/ABI.json';

import PhaseOneYoungBullWLJson from '../assets/lists/0.json';
import PhaseOneBullWLJson from '../assets/lists/1.json';
import PhaseOneOldBullWLJson from '../assets/lists/2.json';
import PhaseTwoBullWLJson from '../assets/lists/4.json';
import PhaseTwoOldBullWLJson from '../assets/lists/5.json';
import FreeMintWLJson from '../assets/lists/6.json';

import WaitingBull from '../assets/bull-waiting.gif';
import SoldOutBull from '../assets/bull-sold-out.png';

const {MerkleTree} = require('merkletreejs');
const keccak256 = require('keccak256');
window.Buffer = window.Buffer || require('buffer').Buffer;

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractChainName = process.env.REACT_APP_CONTRACT_CHAIN_NAME;
const contractChainId = parseInt(process.env.REACT_APP_CONTRACT_CHAIN_ID);

const mint_groups = [0, 1, 2, 3, 4, 5, 6];
const maxSupply = 666;

const mintPhases = ['Mint non commencé', 'Phase 1 - Private', 'Phase 2 - Old Bull Only', 'Phase 3 - Old Bull + Bull', 'Phase 4 - Public', 'Sold Out'];
const mintGroupNames = ['WL Young Bull', 'WL Bull', 'WL Old Bull', 'Public', 'WL Bull', 'WL Old Bull', 'Free Mint'];

const Mint = () => {

    const pageTitle = 'B4D BULL - Mint'

    const [currentStep, setCurrentStep] = useState();
    const [totalSupply, setTotalSupply] = useState(0);

    const { address, isConnected } = useAccount()
    const { chain } = useNetwork();
    const { data: signerData } = useSigner();

    const[mintGroupsDatas, setMintGroupsDatas] = useState([]);
    const wlJsonFiles = [PhaseOneYoungBullWLJson, PhaseOneBullWLJson, PhaseOneOldBullWLJson, null, PhaseTwoBullWLJson, PhaseTwoOldBullWLJson, FreeMintWLJson];

    const [wlProofs, setWLProofs] = useState([]);
    const [isOnList, setIsOnList] = useState([]);
    const [mintracker, setMintTracker] = useState([]);

    // EVENTS
    // CurrentStep
    const getCurrrentStep = useContractRead({
        address: contractAddress,
        abi: abi,
        functionName: 'currentStep',
        chainId: contractChainId, onSuccess(data) { setCurrentStep(data); }
    })
    // Next version
    /*const getTotalSupply = useContractRead({
        address: contractAddress,
        abi: abi,
        functionName: 'totalSupply',
        enabled: false,
        chainId: contractChainId, onSuccess(data) { setTotalSupply(parseInt(data)); }
    })*/
    // CurrentStep update
    const refreshCurrrentStep = useContractEvent({
        address: contractAddress,
        abi: abi,
        eventName: 'stepUpdated',
        chainId: contractChainId,
        listener(new_step) { setCurrentStep(new_step); }
    })
    // New mint update
    const newMint = useContractEvent({
        address: contractAddress,
        abi: abi,
        eventName: 'newMint',
        chainId: contractChainId,
        listener() { getTotalSupply(); }
    })

    const mintSteps = () => {
        return (
            <div id="steps-timeline" className="mb-4"><b>Phases de mint</b><br />
            {mintPhases.map((mintPhase, index) => {

                if (index >= 0 && index < 7) {
                    var mintClass;

                    if (index < currentStep) { mintClass = "previous" }
                    if (index === currentStep) { mintClass = "active" }
                    
                    return <span key={index} className={mintClass}>{mintPhase}</span>;
                }
            })}

        </div>);
    }

    const mintNftHandler = async (mint_group_id, amount) => {
        if (!signerData) return;
        try {
                const nftContract = new ethers.Contract(contractAddress, abi, signerData);
                let nftTxn;

                const toast_loading = toast.loading('Mint de ' + amount + ' NFT - ' + mintGroupNames[mint_group_id]);

                let price = mintGroupsDatas[mint_group_id].price;
                price = price.mul(amount);

                if (mint_group_id == 3) { // Public
                    nftTxn = await nftContract.mint([], mint_group_id, amount, {value: price});
                } else { // WL
                    nftTxn = await nftContract.mint(wlProofs[mint_group_id], mint_group_id, amount, {value: price});
                }

                toast.loading('Mint de ' + amount + ' NFT en cours', {id: toast_loading});
                await nftTxn.wait();

                toast.dismiss(toast_loading);
                toast.success('Mint de ' + amount + ' NFT réussi !');
                getTotalSupply();

        } catch (err) {
            toast.dismiss();
            toast.error('Mint annulé !');
            console.log(err);
            if (err.code === 'UNPREDICTABLE_GAS_LIMIT') { toast.error('Erreur : Phase incorrecte ou adresse non autorisée'); }
            if (err.code === 'INSUFFICIENT_FUNDS') { toast.error('Erreur : Fonds insuffisants'); }
        }
    }

    const getTotalSupply = async () => {
        if (!signerData) return;
        if (chain.id != contractChainId) return;

        try {
            const nftContract = new ethers.Contract(contractAddress, abi, signerData);
            const amount = await nftContract.totalSupply();
            setTotalSupply(parseInt(amount));

        } catch (err) { console.log(err); }
    }

const fetchMintGroupsDatas = async () => {
    if (!signerData) return;
    if (chain.id != contractChainId) return;

    try {
      const nftContract = new ethers.Contract(contractAddress, abi, signerData);
      const mintGroupPromises = mint_groups.map(async (group) => {
        const mintGroupInfos = await nftContract.getMintGroup(group);
        return { id: group, ...mintGroupInfos };
      });
  
      const mintGroupDataResults = await Promise.all(mintGroupPromises);
      setMintGroupsDatas(mintGroupDataResults);

    } catch (error) {
      console.error(error);
    }
  };    

    // ROLES
    const generateProofs = async () => {
        if (!signerData | !isConnected) return;
        if (chain.id != contractChainId) return;

        fetchMintTracker().then(() => {

            let wlAddressesArray = [[],[],[],null,[],[],[]];
            let wlLeavesArray = [[],[],[],null,[],[],[]];
            let wlTreesArray = [[],[],[],null,[],[],[]];
            let wlProofsArray = [[],[],[],null,[],[],[]];

            mint_groups.map(async (group) => {
                if (group != 3) { wlJsonFiles[group].map(a => ( wlAddressesArray[group].push(a.address) )); }
            });

            mint_groups.map(async (group) => {
                if (group != 3) { wlLeavesArray[group] = wlAddressesArray[group].map(address => keccak256(address)); }
            });

            mint_groups.map(async (group) => {
                if (group != 3) { wlTreesArray[group] = new MerkleTree(wlLeavesArray[group], keccak256, {sort: true}); }
            });

            mint_groups.map(async (group) => {
                if (group != 3) { 
                    wlProofsArray[group] = wlTreesArray[group].getHexProof(keccak256(address));
                }
            });

            setWLProofs(wlProofsArray);
        });
    }

    const fetchMintTracker = async () => {
        if (!signerData | !isConnected) return;
        if (chain.id != contractChainId) return;

        try {

            const nftContract = new ethers.Contract(contractAddress, abi, signerData);
            const mintTrackerPromises = mint_groups.map(async (group) => {
              const mintTrackerInfos = await nftContract.getMintTracker(group, address);
              return mintTrackerInfos.toNumber();
            });
        
            const mintTrackerResults = await Promise.all(mintTrackerPromises);
            setMintTracker(mintTrackerResults);

        } catch (err) { console.log(err); }
    }

    const fetchIsOnList = async () => {
        if (!signerData | !isConnected) return;
        if (chain.id != contractChainId) return;
        try {

            const nftContract = new ethers.Contract(contractAddress, abi, signerData);
            const isOnListPromises = mint_groups.map(async (group) => {
                if (group != 3) {
                    const isOnListInfos = await nftContract.isOnList(address, wlProofs[group], group);
                    return isOnListInfos;
                } else { return true; }
            });
        
            const isOnListResults = await Promise.all(isOnListPromises);
            setIsOnList(isOnListResults);

            } catch (err) { console.log(err); }
    }

    const MintBlock = ({ mint_group_id }) => {
   
        const eligibility = isOnList[mint_group_id];
        let eligibilityClass = '';
        if (eligibility == false) { eligibilityClass = ' ineligible'; } else { eligibilityClass = ''; }

        let displayMintGroup = false;

        if (currentStep == 1 && (mint_group_id == 0 || mint_group_id == 1 || mint_group_id == 2 || mint_group_id == 6)) displayMintGroup = true;
        if (currentStep == 2 && (mint_group_id >= 5 &&  mint_group_id <= 6)) displayMintGroup = true;
        if (currentStep == 3 && (mint_group_id >= 4 && mint_group_id <= 6)) displayMintGroup = true;
        if (currentStep == 4 && (mint_group_id >= 3 && mint_group_id <= 6)) displayMintGroup = true;

        // Order
        let displayOrder = '';
        if (mint_group_id == 6) displayOrder = 'order-first';
        if (eligibility == false) displayOrder = 'order-last';

        // Mint btns
        let availableMint = (mintGroupsDatas[mint_group_id].maxPerWallet).toNumber() - mintracker[mint_group_id];
        if(availableMint >= maxSupply - totalSupply) { availableMint = maxSupply - totalSupply; }

        const [mintNumber, setMintNumber] = useState(1);
        const [maxMintNumber, setMaxMintNumber] = useState(availableMint);
    
        const mintMore = () => {
            if(maxMintNumber >= maxSupply - totalSupply) { setMaxMintNumber(maxSupply - totalSupply); }
            setMintNumber(c => Math.min(c + 1, maxMintNumber));
        };
    
        const mintLess = () => {
            if (mintNumber > 1) { setMintNumber(mintNumber-1); }
        };

        const price = (ethers.utils.formatUnits(mintGroupsDatas[mint_group_id].price) * mintNumber).toFixed(3);

        return (<>
        
        {displayMintGroup == true ? (
        <Col sm={12} md={6} xl={3} className={`mb-4 ${displayOrder}`}><Card className="h-100">
                <Card.Body>
                    <Card.Title>{mintGroupNames[mint_group_id]}</Card.Title>

                    {maxSupply != totalSupply ? (<>

                    <p className="mb-2">Mint effectués : {mintracker[mint_group_id]}/{(mintGroupsDatas[mint_group_id].maxPerWallet).toNumber()}</p>

                    {availableMint >= 1 ? (<button onClick={() => {mintNftHandler(mint_group_id, mintNumber).then()}} type="button" className="btn btn-dark">Mint</button>) : (<button type="button" disabled className="btn btn-dark">Aucun mint restant</button>)}

                    {availableMint >= 2 ? (<>
                        <div className="cleafix"></div>
                    <div className="btn-group mt-2 btn-group-sm" role="group">
                        <button type="button" className="btn btn-danger" onClick={mintLess}>-</button>
                        <button type="button" className="btn btn-dark no-cursor">{mintNumber} B4D BULL</button>
                        <button type="button" className="btn btn-success" onClick={mintMore}>+</button>
                    </div>
                    
                    </>) : null}
                    
                    
                    </>) : (<p className="mb-0">Sold Out !</p>) }

                </Card.Body>
                <Card.Footer>{mint_group_id != 6 ? (<>Prix du mint : ~{price} ETH</>) : (<>Mint gratuit</>)}</Card.Footer>
                <div className={eligibilityClass}></div>
        </Card></Col>) : null }</>)
    }

    // Effects
    useEffect(() => {
        generateProofs().then();
        getTotalSupply().then();
        fetchMintGroupsDatas().then();
    }, [signerData])

    useEffect(() => { fetchMintTracker().then(); }, [totalSupply])

    useEffect(() => {
        fetchIsOnList().then();
    }, [address, chain, wlProofs])

    const m_mintUI = () => {
        const listItems = mintGroupsDatas.map((mgDatas) => <MintBlock key={mgDatas.id} mint_group_id={mgDatas.id} /> );

        return(<>

            {mintSteps()}

            <p>{totalSupply}/{maxSupply} B4D Bulls mintés</p><br />

            <Row className="nfts-cards justify-content-center">{listItems}</Row>

            {currentStep == 0 ? (<img src={WaitingBull} className="drop-shadow" />) : null}
            {currentStep == 5 ? (<img src={SoldOutBull} className="drop-shadow" />) : null}

            </>);
    }

    const mintUI = () => {

        if (!isConnected) return;

        return (
            <div className="text-center mb-5">
                { chain.id !== contractChainId ? ( <><p>Veuillez passer sur le réseau {contractChainName}</p><div className="connect-zone my-2"><ConnectButton label="Connecter son wallet" /></div></> )
                : (m_mintUI()) }
            </div>
        )
    }

  return (
    <><Menu className="transparent-menu" />
    <Meta title={pageTitle}/>

    <Container>
        <Row>
            <Col sm={12} className="mt-4"> <h1 className="title my-4">Mint</h1><div className="connect-zone my-2"><ConnectButton chainStatus="none" label="Connecter son wallet" /></div></Col>
            <Col sm={12} className="mt-4">{signerData ? mintUI() : null }</Col>
        </Row>
    </Container>      
    </>
  )
}

export default Mint